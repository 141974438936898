import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import IssuerList from "./components/IssuerList";
import IssuerDetail from "./components/IssuerDetail";
import LoginPage from "./components/LoginPage";
import ProtectedRoute from "./ProtectedRoute.js";
import './tailwind.css';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Login route is always accessible */}
          <Route path="/login" element={<LoginPage/>} />

          {/* Protect routes using ProtectedRoute */}
          <Route exact path="/" element={<ProtectedRoute redirectPath='/login'/>}>
            <Route exact path="/"  element={<IssuerList />}/>
            <Route path="/issuer/:xid"  element={<IssuerDetail />}/>
          </Route>
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
