import axios from 'axios'

const XcreditBackend = axios.create({
    timeout: 50000,
    // baseURL: 'http://localhost:8000/api'
    // baseURL: 'http://192.168.1.84:8000/api'
    baseURL: 'https://dev.xcredit.ai/api'
})


XcreditBackend.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("xcredit-token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


XcreditBackend.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Handle token expiration or invalid token, e.g., by logging out the user
            localStorage.removeItem("xcredit-token");
            window.location.href = "/login";  // Redirect to login page
        }
        return Promise.reject(error);
    }
);

export function getAllIssuerData() {
    return XcreditBackend({
        method: "GET",
        url: "/allIssuerData",
    })
}

export function getTotalCOAmount() {
    return XcreditBackend({
        method: "GET",
        url: "/totalCOAmount",
    })
}

export function getIssuerData(pageSize, pageNum) {
    return XcreditBackend({
        method: "GET",
        url: "/issuerData",
        params: {  // Use params instead of data for GET requests
            pageSize: pageSize,
            pageNum: pageNum
        }
    })
}

export function getIssuerDetail(xid) {
    return XcreditBackend({
        method: "GET",
        url: `/issuerDetail/${xid}`,
    })
}

export function verifyToken(token) {
    return XcreditBackend({
        method: "POST",
        url: "/verify-token",
    })
}

export function login(credentials) {
    return XcreditBackend({
        method: "POST",
        url: "/login",
        headers: {
            "Content-Type": "application/json",
        },
        data: credentials
    })
}
