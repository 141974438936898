import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getIssuerDetail } from "../utils/api";
import { Chart, registerables } from "chart.js";
import 'chart.js/auto'; // Import Chart.js
import Sidebar from "./subcomponents/Sidebar";
import { calAge } from "./subcomponents/utils";
import IssuerDetailMetrics from "./subcomponents/IssuerDetailMetrics";


Chart.register(...registerables);


const mockChartData = {
    labels: ['2019-12', '2020-04', '2020-07', '2020-10', '2021-01', '2021-04'], // X-axis labels representing dates
    ltv: [1000, 950, 900, 850, 870, 890], // LTV data (blue line)
    cost: [7, 50, 100, 150, 220, 300] // Cost data (red line)
};


const IssuerDetail = () => {
  const { xid } = useParams();
  const [accountHeader, setAccountHeader] = useState([]);
  const [accountDetails, setAccountDetails] = useState({});
  const [accountEnrich, setAccountEnrich] = useState({});
  const [chartData, setChartData] = useState(null);
  const [age, setAge] = useState("")

  useEffect(() => {
    getIssuerDetail(xid)
      .then((res) => {
        console.log(res.data);
        setAccountHeader(res.data[0]);
        setAccountDetails(res.data[1]);
        setAccountEnrich(res.data[2]);
        setChartData(mockChartData);
        renderChart(mockChartData);
        const birthDateIndex = res.data[0].indexOf("PBirthdate");
                
        if (birthDateIndex !== -1) {
            const birthDate = res.data[1][birthDateIndex]; 
            const calculatedAge = calAge(birthDate);
            setAge(calculatedAge);
          }
      })
      .catch((error) => console.error("Error fetching account details:", error));
  }, [xid]);

  // Function to render chart
  const renderChart = (data) => {
    const ctx = document.getElementById("accountTrendChart").getContext("2d");
    new Chart(ctx, {
      type: "line",
      data: {
        labels: data.labels, 
        datasets: [
          {
            label: "Life Time Value",
            data: data.ltv, 
            borderColor: "rgba(54, 162, 235, 1)",
            fill: false,
          },
          {
            label: "Cost",
            data: data.cost, 
            borderColor: "rgba(255, 99, 132, 1)",
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  };

  return (
    <div className="flex">
      {/* Fixed Sidebar */}
      <Sidebar />
      
      {/* Main content area */}
      <div className="flex-1 ml-16 flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6 p-6">
        {/* Left Column: Account Details Table */}
        <div className="flex-1 bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Account Details</h2>
          <table className="min-w-full table-auto">
            <tbody>
              {accountHeader.map((header, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 px-4 font-medium">{header}</td>
                  <td className="py-2 px-4">{accountDetails[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Right Column: Graph and Metrics */}
        <div className="flex-1 flex flex-col space-y-6">
            {/* Graph */}
            <div className="bg-white rounded-lg shadow p-6">
                <h2 className="text-xl font-semibold mb-4">Account Trends</h2>
                <div>
                  <canvas id="accountTrendChart" className="w-full h-64"></canvas>
                </div>
            </div>

            {/* Metrics */}
            <IssuerDetailMetrics age={age} enrichData={accountEnrich}/>
        </div>
      </div>
    </div>
  );
};

export default IssuerDetail;
