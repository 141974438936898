import React, { useState, useEffect } from "react";
import IssuerTable from "./subcomponents/IssuerTable";
import { getAllIssuerData } from "../utils/api";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Sidebar from "./subcomponents/Sidebar";


const IssuerList = () => {
  const [issuerDataHeader, setIssuerDataHeader] = useState([]);
  const [issuerDataList, setIssuerDataList] = useState([]);
  const [totalCOAmount, setTotalCOAmount] = useState([])

  useEffect(() => {
    getAllIssuerData()
        .then((res) => {
          setIssuerDataHeader(res.data[0]);
          setIssuerDataList(res.data[1]);
          setTotalCOAmount(res.data[2].toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
        })
        .catch((error) => console.error("Error fetching issuer data:", error));
  }, []);

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar/>

      {/* Main Content */}
      <div className="flex-1 ml-16 p-6">
        {/* Header */}
        <header className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold">X Credit-Debt Pck: 202043 Check & Go</h1>
          {/* <input
            type="text"
            className="border border-gray-300 rounded-full px-4 py-2 w-64"
            placeholder="Search"
          /> */}
        </header>

        {/* Table */}
        <div className="mt-6 bg-white shadow rounded-lg p-4">
          <IssuerTable issuerDataHeader={issuerDataHeader} issuerDataList={issuerDataList}/>
        </div>

      </div>

      {/* Right Sidebar (Statistics) */}
      <aside className="w-80 bg-gray-50 p-6 space-y-6">
        <div className="bg-white p-4 shadow rounded-lg">
          <h3 className="text-lg font-semibold">Credit Statistic</h3>
          <div className="flex justify-around mt-4">
            <div className="text-center">
              <CircularProgressbar
                value={60}
                text={`${60}`}
                styles={buildStyles({
                  pathColor: `#3498db`,
                  textColor: '#000',
                  trailColor: '#ddd',
                })}
              />
              <p className="mt-2 text-sm">Debt Collection Potential</p>
            </div>
            <div className="text-center">
              <CircularProgressbar
                value={50}
                text={`${50}`}
                styles={buildStyles({
                  pathColor: `#e74c3c`,
                  textColor: '#000',
                  trailColor: '#ddd',
                })}
              />
              <p className="mt-2 text-sm">Quality based on location</p>
            </div>
            <div className="text-center">
              <CircularProgressbar
                value={62}
                text={`${62}`}
                styles={buildStyles({
                  pathColor: `#3498db`,
                  textColor: '#000',
                  trailColor: '#ddd',
                })}
              />
              <p className="mt-2 text-sm">Quality based on age</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-4 shadow rounded-lg">
          <h3 className="text-lg font-semibold">Total COAmount</h3>
          <h2 className="text-2xl font-bold mt-2">{totalCOAmount}</h2>
          <p className="text-sm text-gray-500">(All Time)</p>
        </div>
      </aside>
    </div>
  );
};

export default IssuerList;
