import React, { useState, useEffect, useRef } from "react";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid'; // Import sorting icons
import Chart from 'chart.js/auto';


const IssuerDetailMetrics = (props) => {
    const {age, enrichData} = props
    const companyInfo = enrichData[0]?.companyInfo || null;
    const cityInfo = enrichData[0]?.cityInfo || null;
    const bankInfo = enrichData[0]?.bankInfo || null;
    const firstNameInfo = enrichData[0]?.firstNameInfo || null;
    const lastNameInfo = enrichData[0]?.lastNameInfo || null;
    const score = enrichData[0]?.Overall_Score || null;
    const [displayIncome, setDisplayIncome] = useState(false)
    const [displayCity, setDisplayCity] = useState(false)
    const [displayNameRace, setDisplayNameRace] = useState(false)
    const [displayBank, setDisplayBank] = useState(false)
    
    // bunch of box detail info switches
    const displayIncomeDetail = () => {
        setDisplayIncome(!displayIncome)
    }
    const displayCityDetail = () => { 
        setDisplayCity(!displayCity)
    }
    const displayBankDetail = () => {
        setDisplayBank(!displayBank)
    }


    // Create refs for the chart canvas
    const lastNameChartRef = useRef(null);
    const firstNameChartRef = useRef(null);
    const lastNameChartInstance = useRef(null);
    const firstNameChartInstance = useRef(null);



    // Helper to create pie chart data
    const createPieData = (nameInfo) => {
        const labels = Object.keys(nameInfo).filter(key => key !== 'name').map(label => label.toUpperCase());
        const data = Object.values(nameInfo).filter(value => typeof value === 'number').map(value => (value * 100).toFixed(2));

        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
                }
            ]
        };
    };


    // Function to render the chart
    const renderChart = (chartRef, chartInstanceRef, chartData) => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }
        chartInstanceRef.current = new Chart(chartRef.current, {
            type: 'pie',
            data: chartData,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'right',
                        labels: {
                            generateLabels: (chart) => {
                                const data = chart.data.datasets[0].data;
                                return chart.data.labels.map((label, i) => ({
                                    text: `${label}: ${data[i]}%`,
                                    fillStyle: chart.data.datasets[0].backgroundColor[i],
                                }));
                            }
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}%`,
                        },
                    }
                },
            },
        });
    };

    // Initialize or re-render charts when necessary
    useEffect(() => {
        if (lastNameInfo && lastNameChartRef.current) {
            renderChart(lastNameChartRef, lastNameChartInstance, createPieData(lastNameInfo));
        }

        if (firstNameInfo && firstNameChartRef.current) {
            renderChart(firstNameChartRef, firstNameChartInstance, createPieData(firstNameInfo));
        }

        return () => {
            // Clean up the chart instances on component unmount
            if (lastNameChartInstance.current) lastNameChartInstance.current.destroy();
            if (firstNameChartInstance.current) firstNameChartInstance.current.destroy();
        };
    }, [lastNameInfo, firstNameInfo, displayNameRace]);

    return (
        <div className="grid grid-cols-2 gap-4">
            {/* Score box */}
            <div className={`bg-white mt-3 rounded-lg shadow p-4 text-center`}>
                <h3 className="text-lg font-medium">Average Score</h3>
                <p className="text-xl font-bold">{score}</p>
            </div>
            {/* Age box */}
            <div className={`bg-white mt-3 rounded-lg shadow p-4 text-center`}>
                <h3 className="text-lg font-medium">Age</h3>
                <p className="text-xl font-bold">{age}</p>
            </div>

            {/* Income box */}
            <div
                className={`bg-white rounded-lg shadow p-4 text-center ${displayIncome || displayCity ? "col-span-2" : ""}`}
                
            >
                <h3 className="text-lg font-medium">Income</h3>
                {companyInfo ? (
                    displayIncome ? (
                        <div className="mt-3">
                            {Object.entries(companyInfo).map(([key, value]) => (
                                <div>
                                    <p className="text-md" key={key}>
                                        <span className="font-bold">{key}:</span> {value}
                                    </p>
                                </div>
                            ))}
                            <ChevronUpIcon 
                                className="inline-block mt-5 h-5 w-5 text-gray-600" 
                                onClick={displayIncomeDetail}
                            />
                        </div>
                    ) : (
                        <div className="mt-3">
                            <p className="text-l font-bold">
                                High Estimate: {companyInfo["High Estimate"]?.split("year")[0].trim() + " year"}
                            </p>
                            <p className="text-l font-bold">
                                Low Estimate: {companyInfo["Low Estimate"]?.split("year")[0].trim() + " year"}
                            </p>
                            <ChevronDownIcon 
                                className="inline-block mt-5 h-5 w-5 text-gray-600" 
                                onClick={displayIncomeDetail}
                            />
                        </div>
                    )
                ) : (
                    <p className="text-xl font-bold">No company info available</p>
                )}
            </div>

            {/* City Info box */}
            <div
                className={`bg-white rounded-lg shadow p-4 text-center ${displayIncome || displayCity ? "col-span-2" : ""}`}
            >
                <h3 className="text-lg font-medium">City Info</h3>
                {cityInfo ? (
                    displayCity ? (
                        <div className="mt-3">
                            {
                                Object.keys(cityInfo).length != 0? (
                                    <>
                                        <p className="text-l font-bold">{cityInfo['City/County Name']}</p>
                                        <p className="text-l mt-3 font-bold">Income & Poverty</p>
                                        {Object.entries(cityInfo["Income & Poverty"]).map(([key, value]) => (
                                            <div>
                                                <p className="text-md" key={key}>
                                                    {key}: {value}
                                                </p>
                                            </div>

                                        ))}
                                        <p className="text-l mt-3 font-bold">Population</p>
                                        {Object.entries(cityInfo["Population"]).map(([key, value]) => (
                                            <div>
                                                <p className="text-md" key={key}>
                                                    {key}: {value}
                                                </p>
                                            </div>

                                        ))}
                                        <p className="text-l mt-3 font-bold">Race and Hispanic Origin</p>
                                        {Object.entries(cityInfo["Race and Hispanic Origin"]).map(([key, value]) => (
                                            <div>
                                                <p className="text-md" key={key}>
                                                    {key}: {value}
                                                </p>
                                            </div>

                                        ))}
                                    </>
                                ) : (
                                    <p className="text-l font-bold">City Info Not Avaliable</p>
                                )
                            }
                            
                            <ChevronUpIcon 
                                className="inline-block mt-5 mb-0 h-5 w-5 text-gray-600" 
                                onClick={displayCityDetail}
                            />
                  
                        </div>
                    ) : (
                        <div className="mt-3">
                            {
                                Object.keys(cityInfo).length != 0? (
                                    <>
                                        <p className="text-l font-bold">{cityInfo['City/County Name']}</p>
                                        <ChevronDownIcon 
                                            className="inline-block mt-5 mb-0 h-5 w-5 text-gray-600" 
                                            onClick={displayCityDetail}
                                        />                                        
                                    </>
                                ) : (
                                    <p className="text-l font-bold">City Info Not Avaliable</p>
                                )
                            }

                        </div>
                    )
                ) : (
                    <p className="text-xl font-bold">No city info available</p>
                )}
            </div>


            {/* Name Race Decomposition box */}
            <div className={`bg-white rounded-lg shadow p-4 text-center ${displayNameRace || displayBank ? "col-span-2" : ""}`}>
                <h3 className="text-lg font-medium">Name Race Decomposition</h3>
                {lastNameInfo && firstNameInfo ? (
                    displayNameRace ? (
                        <div>
                            <div className="mt-5 text-center mx-auto max-w-sm">
                                {/* Last Name Decomposition */}
                                {
                                    Object.keys(lastNameInfo).length != 0 && (
                                        <>
                                            <p className="text-l font-bold">Last Name: {lastNameInfo.name}</p>
                                            <canvas ref={lastNameChartRef} width="400" height="400" />
                                        </>
       
                                    )
                                }


                                {/* First Name Decomposition */}
                                {
                                    Object.keys(firstNameInfo).length != 0 && (
                                        <>
                                            <p className="text-l font-bold mt-4">First Name: {firstNameInfo.name}</p>
                                            <canvas ref={firstNameChartRef} width="400" height="400" />
                                        </>
       
                                    )
                                }
       
                            </div>
                            <div className="flex justify-center mt-5">
                                <ChevronUpIcon 
                                    className="h-5 w-5 text-gray-600" 
                                    onClick={() => setDisplayNameRace(!displayNameRace)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="mt-3 mx-auto max-w-sm">
                            {/* Brief Info */}
                            {
                                    Object.keys(lastNameInfo).length != 0 && (
                                        <p className="text-l">
                                            <span className="font-bold">Last Name:</span> {lastNameInfo.name} - 
                                            {Object.entries(lastNameInfo)
                                                .filter(([key]) => key !== "name")
                                                .reduce((a, b) => a[1] > b[1] ? a : b)[0].toUpperCase()}
                                        </p>
       
                                    )
                            }
                            {
                                    Object.keys(firstNameInfo).length != 0 && (
                                        <p className="text-l">
                                            <span className="font-bold">First Name:</span> {firstNameInfo.name} - 
                                            {Object.entries(firstNameInfo)
                                                .filter(([key]) => key !== "name")
                                                .reduce((a, b) => a[1] > b[1] ? a : b)[0].toUpperCase()}
                                        </p>
                                    )
                            }
                            {
                                    Object.keys(firstNameInfo).length != 0 ? (
                                        <p className="text-l">
                                            Name Race Info Not Available
                                        </p>
                                    ) : (
                                        <ChevronDownIcon 
                                            className="inline-block mt-5 h-5 w-5 text-gray-600 mx-auto" 
                                            onClick={() => setDisplayNameRace(!displayNameRace)}
                                        />
                                    )
                            }


                        </div>
                    )
                ) : (
                    <p className="text-xl font-bold">No name race info available</p>
                )}
            </div>


            {/* Bank Info box */}
            <div 
                className={`bg-white rounded-lg shadow p-4 text-center ${displayNameRace || displayBank ? "col-span-2" : ""}`}
            >
                <h3 className="text-lg font-medium">Bank Info</h3>
                {bankInfo ? (
                    displayBank ? (
                        <div className="mt-3">
                            <p className="text-l font-bold">{bankInfo["BankName"]}</p>
                            {Object.entries(bankInfo)
                            .filter(([key]) => key !== "BankName")
                            .map(([key, value]) => (
                                <p className="text-md" key={key}>
                                    <span className="font-bold">{key}:</span> {value}
                                </p>
                            ))}
                            <ChevronUpIcon 
                                className="inline-block mt-5 h-5 w-5 text-gray-600" 
                                onClick={() => displayBankDetail()}
                            />
                        </div>
                    ) : (
                        <div className="mt-3">
                            <p className="text-l font-bold">{bankInfo["BankName"]}</p>
                            <ChevronDownIcon 
                                className="inline-block mt-5 h-5 w-5 text-gray-600" 
                                onClick={() => displayBankDetail()}
                            />
                        </div>
                    )
                ) : (
                    <p className="text-xl font-bold">No bank info available</p>
                )}
            </div>            
        </div>
    )
}


export default IssuerDetailMetrics