import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { HomeIcon as HomeOutline, ChartBarIcon as ChartBarOutline, ChatBubbleOvalLeftIcon as ChatOutline } from '@heroicons/react/24/outline';
import { HomeIcon as HomeSolid, ChartBarIcon as ChartBarSolid, ChatBubbleOvalLeftIcon as ChatSolid } from '@heroicons/react/24/solid'; // Import solid icons

const Sidebar = () => {
    const navigate = useNavigate();
    
    // State to handle hover effects for icons
    const [hoveredIcon, setHoveredIcon] = useState(null);

    const toHome = () => {
        navigate('/');
    };

    return (
        <aside className="fixed top-0 left-0 h-screen w-16 bg-blue-600 flex flex-col items-center justify-between py-6">
            <div className="space-y-8">
                {/* Home Icon */}
                {hoveredIcon === 'home' ? (
                    <HomeSolid 
                        className="h-6 w-6 text-white cursor-pointer" 
                        onMouseLeave={() => setHoveredIcon(null)} 
                        onClick={toHome} 
                    />
                ) : (
                    <HomeOutline 
                        className="h-6 w-6 text-white cursor-pointer hover:text-gray-300" 
                        onMouseEnter={() => setHoveredIcon('home')} 
                    />
                )}

                {/* Chart Icon */}
                {hoveredIcon === 'chart' ? (
                    <ChartBarSolid 
                        className="h-6 w-6 text-white cursor-pointer" 
                        onMouseLeave={() => setHoveredIcon(null)} 
                    />
                ) : (
                    <ChartBarOutline 
                        className="h-6 w-6 text-white cursor-pointer hover:text-gray-300" 
                        onMouseEnter={() => setHoveredIcon('chart')} 
                    />
                )}

                {/* Chat Icon */}
                {hoveredIcon === 'chat' ? (
                    <ChatSolid 
                        className="h-6 w-6 text-white cursor-pointer" 
                        onMouseLeave={() => setHoveredIcon(null)} 
                    />
                ) : (
                    <ChatOutline 
                        className="h-6 w-6 text-white cursor-pointer hover:text-gray-300" 
                        onMouseEnter={() => setHoveredIcon('chat')} 
                    />
                )}
            </div>
            <div className="rounded-full w-10 h-10 bg-gray-300"></div> {/* Placeholder for user avatar */}
        </aside>
    );
};

export default Sidebar;
