import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'; // Import sorting icons
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid'; // Import sorting icons
import { sortData } from "./utils";


const IssuerTable = (props) => {
    const navigate = useNavigate();
    const {issuerDataHeader, issuerDataList} = props
    const [filteredDataList, setFilteredDataList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [inputPage, setInputPage] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
    const rowsPerPage = 15 // Display rows per page

    useEffect(() => {
        setFilteredDataList(issuerDataList);
    }, [issuerDataList]);

    // Sorting logic
    const handleSort = (columnIndex) => {
        let direction = "ascending";
        if (sortConfig.key === columnIndex && sortConfig.direction === "ascending") {
            direction = "descending";
        }
    
        const sortedData = [...filteredDataList].sort((a, b) => {
            const aValue = a[columnIndex];
            const bValue = b[columnIndex];
            const aNumber = parseFloat(aValue);
            const bNumber = parseFloat(bValue);
            const datePattern = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
            const dollarPattern = /^\$/;
            const isADate = datePattern.test(aValue);
            const isBDate = datePattern.test(bValue);
            const isADollarValue = dollarPattern.test(aValue);
            const isBDollarValue = dollarPattern.test(bValue);
    
            if (isADate && isBDate) {
                const aDateParts = aValue.split('/');
                const bDateParts = bValue.split('/');
                const aYear = parseInt(aDateParts[2], 10);
                const aMonth = parseInt(aDateParts[0], 10);
                const aDay = parseInt(aDateParts[1], 10);
                const bYear = parseInt(bDateParts[2], 10);
                const bMonth = parseInt(bDateParts[0], 10);
                const bDay = parseInt(bDateParts[1], 10);
    
                if (aYear !== bYear) return direction === "ascending" ? aYear - bYear : bYear - aYear;
                if (aMonth !== bMonth) return direction === "ascending" ? aMonth - bMonth : bMonth - aMonth;
                return direction === "ascending" ? aDay - bDay : bDay - aDay;
            } else if (isADollarValue && isBDollarValue) {
                const aDollarValue = parseFloat(aValue.replace(/[$,]/g, ""));
                const bDollarValue = parseFloat(bValue.replace(/[$,]/g, ""));
                return direction === "ascending" ? aDollarValue - bDollarValue : bDollarValue - aDollarValue;
            } else if (!isNaN(aNumber) && !isNaN(bNumber)) {
                return direction === "ascending" ? aNumber - bNumber : bNumber - aNumber;
            } else {
                return direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
        });
        
        setFilteredDataList(sortedData);
        setSortConfig({ key: columnIndex, direction });
    };

    // Filtering logic
    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        const filteredData = issuerDataList.filter((row) =>
        row.some((cell) => cell.toString().toLowerCase().includes(query))
        );

        setFilteredDataList(filteredData);
        setCurrentPage(1); // Reset to the first page
    };

    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = filteredDataList.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(filteredDataList.length / rowsPerPage);

    const nextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

    const jumpToPage = (pageNum) => {
        const page = Math.max(1, Math.min(totalPages, pageNum));
        setCurrentPage(page);
        setInputPage(""); // Clear input after jumping to page
    };

    const handlePageInputChange = (e) => setInputPage(e.target.value);

    const handlePageInputSubmit = (e) => {
        e.preventDefault();
        if (inputPage && !isNaN(inputPage)) {
            jumpToPage(parseInt(inputPage));
        }
    };

    const getDisplayedPageNumbers = () => {
        const pageNumbers = [];
        const maxDisplayPages = 5;

        let startPage = Math.max(2, currentPage - Math.floor(maxDisplayPages / 2));
        let endPage = Math.min(totalPages - 1, currentPage + Math.floor(maxDisplayPages / 2));

        if (startPage === 2) {
            endPage = Math.min(totalPages - 1, startPage + maxDisplayPages - 1);
        }
        if (endPage === totalPages - 1) {
            startPage = Math.max(2, endPage - maxDisplayPages + 1);
        }

        pageNumbers.push(1);
        if (startPage > 2) pageNumbers.push("...");
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        if (endPage < totalPages - 1) pageNumbers.push("...");
        pageNumbers.push(totalPages);

        return pageNumbers;
    };

    const toDetailPage = (idx) => {
        navigate(`/issuer/${idx}`)
    }

    return (
        <div className="w-full">
            <h2 className="text-lg font-semibold mb-4">Issuer Data</h2>

            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search data..."
                value={searchQuery}
                onChange={handleSearch}
                className="border border-gray-300 rounded-full px-4 py-2 mb-4 w-full"
            />
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow rounded-lg">
                <thead>
                    <tr className="bg-gray-100">
                        {issuerDataHeader.map((header, index) => (
                            <th
                                key={index}
                                onClick={() => handleSort(index)}
                                className="px-4 py-2 text-left font-medium text-gray-600 cursor-pointer"
                            >
                                {header}
                                {/* Dynamically render sort icon based on sortConfig */}
                                {sortConfig.key === index ? (
                                    sortConfig.direction === "ascending" ? (
                                        <ChevronUpIcon className="inline-block ml-1 h-5 w-5 text-gray-600" />
                                    ) : (
                                        <ChevronDownIcon className="inline-block ml-1 h-5 w-5 text-gray-600" />
                                    )
                                ) : (
                                    <ChevronUpDownIcon className="inline-block ml-1 h-5 w-5 text-gray-400" /> // Default sorting icon
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                    <tbody>
                        {currentData.map((account, idx) => (
                            <tr
                                key={idx}
                                onClick={() => toDetailPage(account[0])}
                                className="border-t hover:bg-gray-50 cursor-pointer"
                            >
                                {account.map((dataEntry, index) => (
                                    <td key={index} className="px-4 py-2 text-sm text-gray-700">
                                        {dataEntry}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-4">
                <span>
                    Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, issuerDataList.length)} of {issuerDataList.length} entries
                </span>
                <div className="flex space-x-2">
                    <button onClick={prevPage} disabled={currentPage === 1} className="px-3 py-1 bg-gray-200 rounded">
                        {'<'}
                    </button>

                    {getDisplayedPageNumbers().map((page, index) => (
                        <button
                            key={index}
                            onClick={() => typeof page === "number" && jumpToPage(page)}
                            disabled={page === currentPage}
                            className={`px-3 py-1 rounded ${page === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                        >
                            {page}
                        </button>
                    ))}

                    <button onClick={nextPage} disabled={currentPage === totalPages} className="px-3 py-1 bg-gray-200 rounded">
                        {'>'}
                    </button>
                </div>

                {/* Input to manually jump to a page */}
                <form onSubmit={handlePageInputSubmit} className="flex items-center space-x-2">
                    <input
                        type="text"
                        placeholder="Go to page"
                        value={inputPage}
                        onChange={handlePageInputChange}
                        className="px-3 py-1 border rounded w-32" // Increased width here to w-32
                    />
                    <button type="submit" className="px-3 py-1 bg-blue-500 text-white rounded">
                        Go
                    </button>
                </form>
            </div>
        </div>
    );
};

export default IssuerTable;
