import React, {useState, useEffect} from 'react';
import {
    Routes,
    Route,
    Link,
    Navigate,
    Outlet,
} from 'react-router-dom';
import {verifyToken} from "./utils/api";


export default function ProtectedRoute(props) {
    const {redirectPath} = props
    const xtoken = localStorage.getItem('xcredit-token')
    const [isAuthenticated, setIsAuthenticated] = useState()


    useEffect(() => {
        const token = localStorage.getItem("xcredit-token");
        if (token) {
          verifyToken(token)
            .then(() => {
              setIsAuthenticated(true);  // Token is valid
            })
            .catch(() => {
              setIsAuthenticated(false);  // Token is invalid, remove token
              localStorage.removeItem("xcredit-token");
            });
        } else {
          setIsAuthenticated(false); // No token found
        }
      }, []);
    

    if (xtoken === null) {
        return <Navigate to={redirectPath} reload/>
    }

    if (isAuthenticated === undefined) {
        return null
    }
    return isAuthenticated === true ? <Outlet /> : <Navigate to={redirectPath} reload/>

}